import { Component } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { isBoolean } from 'lodash'
import { InputComponent } from '../input'

@Component({
	selector: 'app-boolean-input',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule],
	templateUrl: './boolean-input.component.html',
	styleUrl: './boolean-input.component.scss'
})
export class BooleanInputComponent<T extends Record<string, any>> extends InputComponent<T> {

	override init(): void {
		this.config.control.valueChanges.subscribe(value => {
			if (!value && value !== false) {
				this.config.control.setValue(<T[string]>false)
			}
		})

		if (!isBoolean(this.config.control.value)) {
			this.config.control.setValue(<T[string]>false)
		}
	}

}
