import { Component, OnInit } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { ApiService } from '../api/services'
import i18n from '../assets/i18n/fr.json'
import { ToastComponent } from './components/common/toast/toast.component'
import { init } from './t'

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [RouterOutlet, ToastComponent]
})
export class AppComponent implements OnInit {

	constructor(translate: TranslateService, api: ApiService) {
		init(translate, api)
		translate.setTranslation('fr', i18n)
		translate.setDefaultLang('fr')
	}

	ngOnInit(): void { }
}
