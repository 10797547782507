import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Order } from '../../../api/models'
import schemas from '../../../api/schemas/schemas'
import { ApiService } from '../../../api/services'
import { ToastService } from '../../components/common/toast/service/toast.service'
import { CrudController, Entity } from '../../crud-controller.interface'
import { SchemaObject } from '../../schema.interface'
import { t } from '../../t'
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component'

@Component({
	selector: 'app-entity-form',
	templateUrl: './entity-form.component.html',
	styleUrls: ['./entity-form.component.scss'],
	standalone: true,
	imports: [DynamicFormComponent]
})
export class EntityFormComponent<E extends Entity> implements OnInit {

	@Input() entity!: string
	@Input() data?: E | undefined
	@Input() refs?: Record<keyof E, string> | undefined
	@Output() save = new EventEmitter<void>()
	@ViewChild('form') form!: DynamicFormComponent<E>
	schema!: SchemaObject<E>
	controller!: CrudController<E>
	precomputed: Record<keyof E, any> = <Record<keyof E, any>>{}
	validateCallback = (document: Record<string, any>) => this.validate(document)

	constructor(
		private readonly api: ApiService,
		private readonly toastService: ToastService,
		private readonly route: ActivatedRoute
	) { }

	ngOnInit(): void {
		if (this.refs) {
			for (const key in this.refs) {
				for (const path of this.route.pathFromRoot) {
					const param = path.snapshot.params[this.refs[key]!]
					if (param) {
						this.precomputed[key] = param
					}
				}
			}
		}

		this.schema = schemas[this.entity]
		this.controller = (<any>this.api)[this.entity.charAt(0).toLowerCase() + this.entity.slice(1)]
	}

	reset(data?: E) {
		this.form.reset(data)
	}

	submit(document: E) {
		this.form.disable()

		let request = null
		if (this.data === document) {
			request = this.controller.update({
				id: document._id,
				body: document
			})
		} else {
			request = this.controller.create({ body: document })
		}

		request.subscribe({
			next: () => {
				this.save.emit()
				this.toastService.success(t(`${this.entity}.form.success.${this.data === document ? 'update' : 'create'}`))
				this.form.enable()
			},
			error: error => {
				this.form.enable()
				throw error
			}
		})
	}

	validate(document: Record<string, any>): Record<string, string> | undefined {
		if (this.entity === 'Order') {
			const order = <Order>document

			if (order.end && order.start && order.end < order.start) {
				return { end: 'end' }
			}
		}

		return undefined
	}
}

