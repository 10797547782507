import { Component } from '@angular/core'
import { TPipe } from '../../t'
import { EntityPageComponent } from '../entity-page/entity-page.component'

@Component({
	selector: 'app-admin-client-page',
	standalone: true,
	imports: [EntityPageComponent, TPipe],
	templateUrl: './admin-client-page.component.html',
	styleUrl: './admin-client-page.component.scss'
})
export class AdminClientPageComponent {

}
