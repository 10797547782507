<nav class="navbar navbar-light bg-secondary navbar-glass navbar-top navbar-expand-lg navbar-glass-shadow">
  <div class="collapse navbar-collapse scrollbar">
    <ul class="navbar-nav">
      @for (elmt of data; track elmt) {
        <li class="nav-item">
          <a class="nav-link text-light" role="button" routerLink="{{ elmt.href }}" routerLinkActive="text-primary-emphasis">{{ elmt.title }}</a>
        </li>
      }
    </ul>
  </div>
</nav>
