import { Component, OnDestroy } from '@angular/core'
import { ToastModel } from './model/toast.model'
import { Subscription } from 'rxjs'
import { ToastService } from './service/toast.service'



@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss'],
	standalone: true,
	imports: []
})
export class ToastComponent implements OnDestroy {

	public toastStack: ToastModel[] = []
	private subscriptions!: Subscription
	public class!: string

	constructor( private toasterService: ToastService ) {
		this.subscriptions = this.toasterService.toastState.subscribe(
			(toastModel: ToastModel) => {
				this.toastStack.push(toastModel)
			}
		)
	}

	public close(position: number): void {
		this.toastStack.splice(position, 1)
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe()
		this.toastStack.length = 0
	}

}
