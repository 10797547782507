
import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-modal',
	standalone: true,
	imports: [],
	templateUrl: './modal.component.html',
	styleUrl: './modal.component.scss'
})
export class ModalComponent {

	@Input() title?: string
	opened = false
	displayed = false

	constructor() {
		document.addEventListener('keydown', event => {
			if (event.key === 'Escape') {
				this.close()
			}
		})
	}

	open() {
		if (this.opened) {
			return
		}

		this.opened = true
		document.body.classList.add('modal-open')

		setTimeout(() => this.displayed = true, 50)
	}

	close() {
		if (!this.displayed) {
			return
		}

		this.displayed = false

		setTimeout(() => {
			this.opened = false
			document.body.classList.remove('modal-open')
		}, 150)
	}

}
