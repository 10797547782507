import { Pipe, PipeTransform } from '@angular/core'
import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslatePipe, TranslateService } from '@ngx-translate/core'
import { isObjectLike } from 'lodash'
import { ApiService } from '../api/services'
import { environment } from './environments/environment'

function interpolate(params: any[]) {
	if (isObjectLike(params[0])) {
		return params[0]
	}

	const result: Record<number, any> = {}

	for (let i = 0; i < params.length; i++) {
		result[i] = params[i]
	}

	return result
}

@Pipe({
	name: 't',
	standalone: true
})
export class TPipe implements PipeTransform {

	constructor(private readonly translatePipe: TranslatePipe) { }

	transform(value: string, ...args: any[]): string {
		return this.translatePipe.transform(value, interpolate(args))
	}

}

let translate: TranslateService
let api: ApiService
export function init(translateService: TranslateService, apiService: ApiService) {
	translate = translateService
	api = apiService
}

export function t(key: string | Array<string>, ...params: any[]) {
	if (!translate) return ''

	return translate.instant(key, interpolate(params))
}

export class AutoExtractHandler implements MissingTranslationHandler {
	handle(params: MissingTranslationHandlerParams) {
		if (environment.autoExtractI18n) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore There is no dev controler in production but this is only used in development
			api.dev.addI18N({ key: params.key }).subscribe()
		}

		return `$${params.key}$`
	}
}
