import { Component } from '@angular/core'
import { AbstractControl, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms'
import { InputComponent } from '../input'

@Component({
	selector: 'app-number-input',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule],
	templateUrl: './number-input.component.html',
	styleUrl: './number-input.component.scss'
})
export class NumberInputComponent<T extends Record<string, any>> extends InputComponent<T> {

	override validators() {
		const validators = []

		if (this.config.format?.includes('int')) {
			validators.push(intValidator)
		}

		return validators
	}

}

function intValidator(control: AbstractControl): ValidationErrors | null {
	if (control.value && !Number.isInteger(control.value)) {
		return { int: 'not_integer' }
	}

	return null
}
