import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ToastModel, ToastType } from '../model/toast.model'

@Injectable({
	providedIn: 'root'
})
export class ToastService {
	toastState = new BehaviorSubject<ToastModel>(new ToastModel(false))

	error(message: string, seconds: number = 5) {
		this.show(message, ToastType.Error, seconds)
	}

	warning(message: string, seconds: number = 5) {
		this.show(message, ToastType.Warning, seconds)
	}

	success(message: string, seconds: number = 5) {
		this.show(message, ToastType.Success, seconds)
	}

	none(message: string, seconds: number = 5) {
		this.show(message, ToastType.None, seconds)
	}

	show(message: string, type: ToastType, seconds: number = 5) {
		if (seconds <= 0) {
			seconds = 5
		}

		const toast = new ToastModel(true)
		toast.message = message
		toast.type = type

		this.toastState.next(toast)
		setTimeout(() => toast.visible = false, seconds * 1000)
	}
}
