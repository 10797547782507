import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { DialogComponent } from './dialog.component'

@Injectable({
	providedIn: 'root'
})
export class DialogService {

	private dialog!: DialogComponent

	constructor() { }

	register(dialog: DialogComponent) {
		this.dialog = dialog
	}

	confirm(message: string): Observable<void> {
		if (this.dialog.opened) {
			throw new Error('Dialog already opened')
		}

		return new Observable<void>(observer => {
			this.dialog.open(message, observer)
		})
	}
}
