import { Component } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { InputComponent } from '../input'

@Component({
	selector: 'app-object-input',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule],
	templateUrl: './object-input.component.html',
	styleUrl: './object-input.component.scss'
})
export class ObjectInputComponent<T extends Record<string, any>> extends InputComponent<T> {

}
