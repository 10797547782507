
import { Component } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TPipe } from '../../../t'
import { InputComponent } from '../input'

@Component({
	selector: 'app-enum-input',
	templateUrl: './enum-input.component.html',
	styleUrls: ['./enum-input.component.scss'],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, TPipe]
})
export class EnumInputComponent<T extends Record<string, any>> extends InputComponent<T> {
}
