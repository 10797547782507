
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { Entity } from '../../crud-controller.interface'
import { EntityFormComponent } from '../../form/entity-form/entity-form.component'
import { ModalComponent } from '../../modal/modal.component'
import { TPipe } from '../../t'
import { EntityTableComponent } from '../../table/entity-table/entity-table.component'


@Component({
	selector: 'app-entity-page',
	standalone: true,
	imports: [EntityTableComponent, EntityFormComponent, TPipe, ModalComponent],
	templateUrl: './entity-page.component.html',
	styleUrl: './entity-page.component.scss'
})
export class EntityPageComponent<T extends Entity> implements OnInit {

	@Input() entity!: string
	@Input() title!: string
	@Input() actions?: string
	@Input() link?: string
	@Input() refs?: Record<string, string>
	@ViewChild('modal') modal!: ModalComponent
	@ViewChild('form') form!: EntityFormComponent<T>
	@ViewChild('table') table!: EntityTableComponent<T>

	actionsList: string[] = []

	ngOnInit(): void {
		if (this.actions) {
			this.actionsList = this.actions.split(',').map(action => action.trim())
		}
	}

	create() {
		this.form.reset()
		this.modal.open()
	}

	closeModal() {
		this.modal.close()
		this.table.refresh()
	}
}
