<div class="modal fade" [style.display]="opened ? 'block' : 'none'" [class.show]="displayed">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content border-0">
      <div class="modal-body">
        {{ message }}
      </div>
      <div class="modal-footer">
        <button class="btn btn-link" type="button" (click)="close()">{{ "cancel" | t }}</button>
        <button class="btn btn-primary" type="button" (click)="close(true)">{{ "confirm" | t }}</button>
      </div>
    </div>
  </div>
</div>
@if (opened) {
  <div class="modal-backdrop fade" [class.show]="displayed"></div>
}
