import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '../auth.service'
import { t, TPipe } from '../t'
import { FormsModule } from '@angular/forms'


@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	standalone: true,
	imports: [FormsModule, TPipe]
})
export class LoginComponent implements OnInit {

	message!: string
	name!: string
	password!: string
	initialized = false

	constructor(
		private readonly authService: AuthService,
		private readonly router: Router
	) {}

	ngOnInit(): void {
		this.authService.logout().subscribe(() => this.initialized = true)
	}

	setMessage() {
		if (this.authService.getUser()) {
			this.message = t('login.success')
		} else {
			this.message = t('login.bad-password')
		}
	}

	login() {
		this.authService.login(this.name, this.password).subscribe({
			next: () => {
				this.setMessage()
				this.router.navigateByUrl(this.router.parseUrl(this.router.url).queryParams['redirectUrl'])
			},
			error: () => this.setMessage()
		})
	}
}
