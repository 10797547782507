import '@angular/platform-browser-dynamic'

import { DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import fr from '@angular/common/locales/fr'
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser'
import { provideRouter, withComponentInputBinding } from '@angular/router'
import { MissingTranslationHandler, TranslateCompiler, TranslateModule, TranslatePipe } from '@ngx-translate/core'
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler'
import { ApiModule } from './api/api.module'
import { AppComponent } from './app/app.component'
import { AuthInterceptor } from './app/auth.interceptor'
import { AuthService } from './app/auth.service'
import { ToastService } from './app/components/common/toast/service/toast.service'
import { environment } from './app/environments/environment'
import { GlobalErrorHandler } from './app/error-handler'
import { routes } from './app/routes'
import { AutoExtractHandler } from './app/t'

registerLocaleData(fr)

bootstrapApplication(AppComponent, {
	providers: [
		{ provide: LOCALE_ID, useValue: 'fr' },
		{ provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { timezone: 'GMT+1' } },
		importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, ApiModule.forRoot({ rootUrl: environment.apiUrl }), TranslateModule.forRoot({
			compiler: {
				provide: TranslateCompiler,
				useClass: TranslateMessageFormatCompiler
			},
			missingTranslationHandler: {
				provide: MissingTranslationHandler,
				useClass: AutoExtractHandler
			}
		})),
		provideRouter(routes, withComponentInputBinding()),
		ToastService,
		AuthService,
		{
			provide: APP_INITIALIZER,
			useFactory: (auth: AuthService) => () => auth.initialize(),
			deps: [AuthService],
			multi: true
		},
		AuthInterceptor,
		{
			provide: HTTP_INTERCEPTORS,
			useExisting: AuthInterceptor,
			multi: true
		},
		TranslatePipe,
		{ provide: ErrorHandler, useFactory(toastService: ToastService) { return new GlobalErrorHandler(toastService) }, deps: [ToastService] },
		provideHttpClient(withInterceptorsFromDi())
	]
})
	.catch(err => console.error(err))
