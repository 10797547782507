@if (remuneration && areas) {
  <form (submit)="submit()" class="row col-2">
    @for (area of areas; track area._id; let i = $index) {
      <div class="mb-4">
        <label for="rem-{{ area._id }}">{{ area.name }} - {{ area.label }}</label>
        <input type="number" name="rem-{{ area._id }}" id="rem-{{ area._id }}" class="form-control" min="0" max="1" step="0.1" [(ngModel)]="remuneration[i]!.revShare" />
      </div>
    }

    <button type="submit" [disabled]="disabled" class="btn btn-primary me-1 mb-1 mt-3">
      {{ "form.submit" | t }}
    </button>
  </form>
}
