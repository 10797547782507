import { KeyValuePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms'
import { SchemaObject } from '../../schema.interface'
import { TPipe } from '../../t'
import { ArrayEnumInputComponent } from '../element/array-enum-input/array-enum-input.component'
import { ArrayObjectInputComponent } from '../element/array-object-input/array-object-input.component'
import { ArrayStringInputComponent } from '../element/array-string-input/array-string-input.component'
import { BooleanInputComponent } from '../element/boolean-input/boolean-input.component'
import { EnumInputComponent } from '../element/enum-input/enum-input.component'
import { NumberInputComponent } from '../element/number-input/number-input.component'
import { ObjectInputComponent } from '../element/object-input/object-input.component'
import { RefInputComponent } from '../element/ref-input/ref-input.component'
import { TextInputComponent } from '../element/text-input/text-input.component'
import { DynamicFormElement } from './dynamic-form.interface'

@Component({
	selector: 'app-dynamic-form',
	templateUrl: './dynamic-form.component.html',
	styleUrls: ['./dynamic-form.component.scss'],
	standalone: true,
	imports: [
		FormsModule,
		ReactiveFormsModule,
		TextInputComponent,
		EnumInputComponent,
		ArrayEnumInputComponent,
		KeyValuePipe,
		TPipe,
		NumberInputComponent,
		RefInputComponent,
		ObjectInputComponent,
		ArrayObjectInputComponent,
		BooleanInputComponent,
		ArrayStringInputComponent
	]
})
export class DynamicFormComponent<T extends Record<string, any>> implements OnInit {

	@Input() name!: string
	@Input() schema!: SchemaObject<T>
	@Input() data?: T | undefined
	@Input() precomputed?: Record<keyof T, any>
	@Input() validation?: (document: Partial<T>) => Record<string, string> | undefined
	@Input() subform = false
	@Output() save = new EventEmitter<T>()
	document!: Partial<T>
	elements: DynamicFormElement<T>[] = []
	form!: FormGroup
	disabled = false

	ngOnInit(): void {
		this.document = this.data ?? {}

		const controls: Record<string, FormControl> = {}

		for (const key of this.schema.order!) {
			const properties = <SchemaObject>this.schema.properties![key]!

			if (properties.readOnly || this.precomputed?.[key] !== undefined) {
				continue
			}

			const element: DynamicFormElement<T> = {
				key: key,
				name: this.name,
				component: '',
				control: new FormControl<T[string] | undefined>(this.document[key]),
				optional: !this.schema.required?.includes(key),
				...properties
			}

			element.component = this.getComponent(element)
			element.control.setValidators(this.getValidators(element))

			controls[key] = element.control

			element.control.valueChanges.subscribe(value => {
				this.document[<keyof T>key] = value === null ? undefined : value
			})

			this.elements.push(element)
		}

		this.form = new FormGroup(controls)

		setTimeout(() => this.reset())
	}

	private getComponent(element: DynamicFormElement<T>) {
		if (element.items?.enum) {
			return 'array-enum'
		}

		if (element.items?.$ref) {
			return 'array-object'
		}

		if (element.items?.type === 'string') {
			return 'array-string'
		}

		if (element.$ref) {
			return 'object'
		}

		if (element.enum) {
			return 'enum'
		}

		if (element.type === 'number') {
			return 'number'
		}

		if (element.type === 'boolean') {
			return 'boolean'
		}

		if (element.entity) {
			return 'ref'
		}

		return 'text'
	}

	private getValidators(element: DynamicFormElement<T>) {
		const validators = []

		if (!element.optional && !element.items) {
			validators.push(Validators.required)
		}

		if (!element.optional && element.items) {
			validators.push(requiredArray)
		}

		return validators
	}

	reset(data?: T) {
		this.document = data ?? this.data ?? {}

		for (const element of this.elements) {
			const value = this.document[element.key] ?? element.default
			element.control.setValue(value !== undefined ? <T[string]>value : null)

			if ((data || this.data) && element.updatable === false) {
				element.control.disable()
			}
		}
	}

	validate() {
		let result = true

		for (const element of this.elements) {
			if (!element.input!.validate()) {
				result = false
			}
		}

		return !result ? false : this.form.valid
	}

	submit() {
		if (this.subform || this.disabled) {
			return
		}

		if (!this.validate()) {
			return
		}

		const errors = this.validation?.(this.document)
		if (errors) {
			for (const key in errors) {
				const element = this.elements.find(element => element.key === key)
				if (element) {
					element.control.setErrors({ [errors[key]!]: true })
				}
			}
			return
		}

		for (const key in this.precomputed) {
			this.document[key] = this.precomputed[key]
		}

		this.save.emit(<T>this.document)
	}

	disable() {
		this.disabled = true
	}

	enable() {
		this.disabled = false
	}
}

function requiredArray(control: AbstractControl): ValidationErrors | null {
	return !Array.isArray(control.value) ? { requiredArray: true } : null
}
