import { JsonPipe } from '@angular/common'
import { Component } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TPipe } from '../../../t'
import { InputComponent } from '../input'

@Component({
	selector: 'app-array-enum-input',
	templateUrl: './array-enum-input.component.html',
	styleUrls: ['./array-enum-input.component.scss'],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, JsonPipe, TPipe]
})
export class ArrayEnumInputComponent<T extends Record<string, any>> extends InputComponent<T> {
	checkboxes: Record<string, boolean> = {}

	change() {
		const result: string[] = []

		for (const key in this.checkboxes) {
			if (this.checkboxes[key]) {
				result.push(key)
			}
		}

		this.config.control.setValue(<T[string]>result)
	}

	override init() {
		this.config.control.valueChanges.subscribe(value => {
			this.checkboxes = {}

			if (Array.isArray(value)) {
				for (const key of <string[]>value) {
					this.checkboxes[key] = true
				}
			}
		})
	}
}
