<div class="modal fade" [style.display]="opened ? 'block' : 'none'" [class.show]="displayed">
  <div class="modal-dialog modal-lg mt-6">
    <div class="modal-content border-0">
      @if (title) {
        <div class="modal-header bg-body-tertiary">
          <h3 class="modal-title">{{ title }}</h3>
          <button class="btn-close btn btn-circle" type="button" (click)="close()"></button>
        </div>
      }
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
@if (opened) {
  <div class="modal-backdrop fade" [class.show]="displayed"></div>
}
