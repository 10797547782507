<button class="btn btn-secondary fs-8 pt-0 pb-0 ps-1 pe-1 mb-2 ms-2 mt-2" type="button" (click)="add()"><i class="bi-plus-lg"></i></button>

<div class="row ms-2 me-2">
  @for (elmt of value; track i; let first = $first, last = $last, i = $index) {
    <div class="card col-11 mb-4">
      <div class="card-body">
        <input class="form-control mt-2 mb-2" type="text" [(ngModel)]="value[i]" />
      </div>
    </div>
    <div class="col-1">
      <button [disabled]="first" class="btn btn-secondary fs-8 pt-0 pb-0 ps-1 pe-1 mb-1" type="button" (click)="up(i)"><i class="bi-arrow-up"></i></button>
      <button [disabled]="last" class="btn btn-secondary fs-8 pt-0 pb-0 ps-1 pe-1 mb-1" type="button" (click)="down(i)"><i class="bi-arrow-down"></i></button>
      <button class="btn btn-secondary fs-8 pt-0 pb-0 ps-1 pe-1" type="button" (click)="delete(i)"><i class="bi-trash"></i></button>
    </div>
  }
</div>
