
import { Component } from '@angular/core'
import { Observer } from 'rxjs'
import { TPipe } from '../t'
import { DialogService } from './dialog.service'

@Component({
	selector: 'app-dialog',
	standalone: true,
	imports: [TPipe],
	templateUrl: './dialog.component.html',
	styleUrl: './dialog.component.scss'
})
export class DialogComponent {
	opened = false
	displayed = false
	message = ''
	observer!: Observer<void>

	constructor(service: DialogService) {
		service.register(this)

		document.addEventListener('keydown', event => {
			if (event.key === 'Escape') {
				this.close()
			}
		})
	}

	open(message: string, observer: Observer<void>) {
		if (this.opened) {
			return
		}

		this.message = message
		this.observer = observer


		this.opened = true
		document.body.classList.add('modal-open')

		setTimeout(() => this.displayed = true, 50)
	}

	close(confirm: boolean = false) {
		if (!this.displayed) {
			return
		}

		if (confirm) {
			this.observer.next()
		}

		this.observer.complete()

		this.displayed = false

		setTimeout(() => {
			this.opened = false
			document.body.classList.remove('modal-open')
		}, 150)
	}
}
