@if (client) {
  <div class="card">
    <div class="card-header">
      <h2 class="me-5">{{ client.name }}</h2>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" routerLink="order" routerLinkActive="active">{{ "orders" | t }}</a>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <router-outlet></router-outlet>
    </div>
  </div>
}
