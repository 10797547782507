import { ErrorHandler } from '@angular/core'
import { ToastService } from './components/common/toast/service/toast.service'
import { t } from './t'

export class GlobalErrorHandler implements ErrorHandler {

	constructor(private toastService: ToastService) { }

	handleError(error: any) {
		console.error(error)

		if (error?.error?.errors?.length) {
			for (const e of error.error.errors) {
				this.display(t(e.key))
			}
		} else if (error?.status) {
			this.display(t('error.' + error.status))
		} else {
			this.display(t('error.unknown'))
		}
	}

	display(error: any) {
		this.toastService.error(error)
	}
}
