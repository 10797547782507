
import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { switchMap } from 'rxjs'
import schemas from '../../../api/schemas/schemas'
import { ApiService } from '../../../api/services'
import { CrudController, Entity, FindParams, Page } from '../../crud-controller.interface'
import { DialogService } from '../../dialog/dialog.service'
import { EntityFormComponent } from '../../form/entity-form/entity-form.component'
import { ModalComponent } from '../../modal/modal.component'
import { SchemaObject } from '../../schema.interface'
import { TPipe, t } from '../../t'
import { label } from '../../util'
import { DynamicTableComponent } from '../dynamic-table/dynamic-table.component'

@Component({
	selector: 'app-entity-table',
	standalone: true,
	imports: [DynamicTableComponent, EntityFormComponent, ModalComponent, TPipe],
	templateUrl: './entity-table.component.html',
	styleUrl: './entity-table.component.scss'
})
export class EntityTableComponent<E extends Entity> implements OnInit {

	@Input() entity!: string
	@Input() actions: string[] = []
	@Input() link?: string
	@Input() refs?: Record<keyof E, string> | undefined
	@ViewChild('table') table!: DynamicTableComponent<E>
	@ViewChild('modal') modal!: ModalComponent
	@ViewChild('form') form!: EntityFormComponent<E>
	schema!: SchemaObject<E>
	controller!: CrudController<E>
	params: FindParams<E> = { page: 1, limit: 50 }
	data?: Page<E>
	item?: E
	filtered!: string[]
	filters!: Record<keyof E, E[keyof E]>

	constructor(
		private readonly api: ApiService,
		private readonly dialog: DialogService,
		private readonly route: ActivatedRoute
	) { }

	ngOnInit(): void {
		this.filtered = []
		this.filters = <Record<keyof E, E[keyof E]>>{}

		if (this.refs) {
			for (const key in this.refs) {
				this.filtered.push(key)

				for (const path of this.route.pathFromRoot) {
					const param = path.snapshot.params[this.refs[key]!]
					if (param) {
						this.filters[key] = param
					}
				}
			}
		}

		this.schema = schemas[this.entity]
		this.controller = (<any>this.api)[this.entity.charAt(0).toLowerCase() + this.entity.slice(1)]
		this.refresh()
	}

	refresh() {
		this.controller.find({
			body: {
				...this.params,
				filters: this.filters
			}
		}).subscribe(data => {
			this.data = data
			this.params.page = data.page
			this.params.limit = data.limit
			this.params.search = data.search
			this.params.sort = data.sort
			this.params.order = data.order
		})
	}

	closeModal() {
		this.modal.close()
		this.refresh()
	}

	action(action: string, item: E) {
		if (action === 'delete') {
			this.delete(item)
		} else if (action === 'update') {
			this.update(item)
		}
	}

	update(item: E) {
		this.item = item
		this.form.reset(item)
		this.modal.open()
	}

	delete(item: E) {
		const message = t('entity.delete.confirm', t(`${this.entity}.human-name`, label(item)))

		this.dialog.confirm(message)
			.pipe(switchMap(() => this.controller.delete({ id: item._id })))
			.subscribe(() => this.refresh())
	}

}
