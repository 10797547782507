<div class="card">
  <div class="card-header d-flex justify-content-between">
    <h2>{{ title }}</h2>
    <button class="btn btn-primary" (click)="create()"><i class="bi-plus-lg me-2"></i>{{ "add" | t }}</button>
  </div>
  <div class="card-body">
    <app-entity-table #table entity="{{ entity }}" [actions]="actionsList" link="{{ link }}" [refs]="refs"></app-entity-table>
  </div>
</div>

<app-modal #modal title="{{ entity + '.form.title.create' | t }}">
  <app-entity-form #form entity="{{ entity }}" [refs]="refs" (save)="closeModal()"></app-entity-form>
</app-modal>
