<app-menu></app-menu>

<div class="content mt-3">
  <div class="row">
    <div class="col">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-dialog></app-dialog>
