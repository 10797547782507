import { Component, Input, OnInit } from '@angular/core'
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router'
import { Provider } from '../../../api/models'
import { ApiService } from '../../../api/services'
import { TPipe } from '../../t'

@Component({
	selector: 'app-provider-page',
	standalone: true,
	imports: [TPipe, RouterLink, RouterLinkActive, RouterOutlet],
	templateUrl: './provider-page.component.html',
	styleUrl: './provider-page.component.scss'
})
export class ProviderPageComponent implements OnInit {

	@Input() id!: string
	provider!: Provider

	constructor(private api: ApiService) { }

	ngOnInit(): void {
		this.api.provider.read({ id: this.id }).subscribe(provider => this.provider = provider)
	}
}
