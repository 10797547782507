import { Component, Input, OnInit } from '@angular/core'
import { AsyncValidatorFn, ValidatorFn } from '@angular/forms'
import { DynamicFormElement } from '../dynamic-form/dynamic-form.interface'

@Component({
	selector: 'app-input',
	template: '',
	styleUrls: []
})
export class InputComponent<T extends Record<string, any>> implements OnInit {
	@Input() config!: DynamicFormElement<T>
	id!: string

	constructor() {
		this.id = String(Math.random())
	}

	ngOnInit() {
		this.config.input = this
		this.config.control.addValidators(this.validators())
		this.config.control.addAsyncValidators(this.asyncValidators())
		this.init()
	}

	validate() {
		this.config.control.markAsTouched()

		if (this.config.control.disabled) {
			this.config.control.setErrors(null)
			return true
		}

		return this.config.control.valid
	}

	protected init() {}

	protected validators(): ValidatorFn[] {
		return []
	}

	protected asyncValidators(): AsyncValidatorFn[] {
		return []
	}
}
