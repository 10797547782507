<section class="h-100">
  <header class="container h-100">
    <div class="d-flex align-items-center justify-content-center h-100">
      <div class="d-flex flex-column">
        <div class="card text-center" style="width: 700px">
          <div class="card-header h5 text-white bg-primary">{{ "forgot-password.title" | t }}</div>
          <div class="card-body px-5">
            <p class="card-text py-2">
              {{ message }}
            </p>
            <!-- Start of structural guidelines -->
            <div class="form-outline">
              @if (!formSended) {
                <input type="email" id="typeEmail" [(ngModel)]="email" name="email" class="form-control my-3" placeholder="Ex. dav@exemple.com" required />
              }
            </div>
            <!-- Error Alert -->
            @if (error) {
              <div class="alert alert-danger alert-dismissible fade show">
                {{ "forgot-password.error" | t }}
              </div>
            }
            @if (!formSended) {
              <button (click)="forgotPassword()" class="btn btn-primary w-100">{{ "forgot-password.submit" | t }}</button>
            }
            <!-- End of structural guidelines -->
            <div class="d-flex justify-content-between mt-4">
              <div class="col align-self-end">
                @if (!formSended) {
                  <a href="/login">{{ "forgot-password.back-home" | t }}</a>
                }
                @if (formSended) {
                  <a href="/public/forgotpassword">{{ "forgot-password.back" | t }}</a>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</section>
