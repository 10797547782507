import { Routes } from '@angular/router'
import { DashboardComponent } from './dashboard/dashboard.component'
import { ForgotPasswordComponent } from './forgotpassword/forgotpassword.component'
import { LoginComponent } from './login/login.component'
import { MainComponent } from './main/main.component'
import { AdminAreaPageComponent } from './page/admin-area-page/admin-area-page.component'
import { AdminClientPageComponent } from './page/admin-client-page/admin-client-page.component'
import { AdminProviderPageComponent } from './page/admin-provider-page/admin-provider-page.component'
import { AdminSitekeyPageComponent } from './page/admin-sitekey-page/admin-sitekey-page.component'
import { AdminUserPageComponent } from './page/admin-user-page/admin-user-page.component'
import { ClientPageComponent } from './page/client-page/client-page.component'
import { OrderPageComponent } from './page/order-page/order-page.component'
import { ProviderPageComponent } from './page/provider-page/provider-page.component'
import { ProviderRemunerationPageComponent } from './page/provider-remuneration-page/provider-remuneration-page.component'
import { ResetpasswordComponent } from './resetpassword/resetpassword.component'

export const routes: Routes = [
	{ path: 'public/forgotpassword', component: ForgotPasswordComponent },
	{ path: 'public/resetpassword/:token', component: ResetpasswordComponent },
	{ path: 'login', component: LoginComponent },
	{
		path: '', component: MainComponent,
		children: [
			{ path: '', component: DashboardComponent },
			{ path: 'user', component: AdminUserPageComponent },
			{ path: 'client', component: AdminClientPageComponent },
			{ path: 'client/:id', component: ClientPageComponent,
				children: [
					{ path: 'order', component:OrderPageComponent}
				]
			},
			{ path: 'area', component: AdminAreaPageComponent },
			{ path: 'provider', component: AdminProviderPageComponent },
			{
				path: 'provider/:id',
				component: ProviderPageComponent,
				children: [
					{ path: 'sitekey', component: AdminSitekeyPageComponent },
					{ path: 'remuneration', component: ProviderRemunerationPageComponent }
				]
			}
		]
	}
]
