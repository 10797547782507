import { Component } from '@angular/core'
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router'
import { TPipe } from '../../t'
import { EntityPageComponent } from '../entity-page/entity-page.component'

@Component({
	selector: 'app-order-page',
	standalone: true,
	imports: [EntityPageComponent,TPipe, RouterLink, RouterLinkActive, RouterOutlet],
	templateUrl: './order-page.component.html',
	styleUrl: './order-page.component.scss'
})
export class OrderPageComponent {

}
