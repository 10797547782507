import { Injectable } from '@angular/core'
import { Params, Router } from '@angular/router'
import { catchError, tap } from 'rxjs'
import { User } from '../api/models'
import { ApiService } from '../api/services'

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	private user?: User | undefined

	constructor(
		private readonly api: ApiService,
		private readonly router: Router
	) { }

	initialize() {
		// Excluded Redirects
		if (window.location.pathname.startsWith('/public') || window.location.pathname.startsWith('/login')) return null

		return this.check().pipe(catchError(() => {
			const queryParams: Params = {}

			const redirection = window.location.pathname + window.location.search
			if (redirection !== '/') {
				queryParams['redirectUrl'] = redirection
			}

			return this.router.navigate(['/login'], { queryParams })
		}))
	}

	check() {
		return this.api.auth.me().pipe(tap(user => this.user = user))
	}

	login(email: string, password: string) {
		return this.api.auth.login({ body: { email, password } }).pipe(tap(user => this.user = user))
	}

	logout() {
		return this.api.auth.logout().pipe(tap(() => this.user = undefined))
	}

	getUser() {
		return this.user
	}
}
