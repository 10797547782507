import { Component, Input, OnInit } from '@angular/core'
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router'
import { Client } from '../../../api/models'
import { ApiService } from '../../../api/services'
import { TPipe } from '../../t'


@Component({
	selector: 'app-client-page',
	standalone: true,	imports: [TPipe, RouterLink, RouterLinkActive, RouterOutlet],
	templateUrl: './client-page.component.html',
	styleUrl: './client-page.component.scss'
})
export class ClientPageComponent implements OnInit {

	@Input() id!: string
	client!: Client

	constructor(private api: ApiService) { }

	ngOnInit(): void {
		this.api.client.read({ id: this.id }).subscribe(client => this.client = client)
	}
}
