import { Component } from '@angular/core'
import { ApiService } from '../../api/services'
import { t, TPipe } from '../t'
import { FormsModule } from '@angular/forms'


@Component({
	selector: 'app-forgotpassword',
	templateUrl: './forgotpassword.component.html',
	standalone: true,
	imports: [FormsModule, TPipe]
})
export class ForgotPasswordComponent {

	email! : string
	formSended = false
	result! : boolean
	message = t('forgot-password.text')
	error! : boolean

	constructor(
		private readonly api: ApiService
	) {}

	async forgotPassword() {
		await this.api.auth.forgotPassword({body : { userMail: this.email}}).subscribe({
			next: () => {
				this.result = true
				this.setMessage()
				this.error =  false
				return this.result
			},
			error: () => {
				this.error = true
			}
		})
	}

	setMessage() {
		this.message = t('forgot-password.success', { email: this.email })
		this.formSended = true
	}
}
