import { Component, Input, OnInit } from '@angular/core'
import { TPipe } from '../t'

const ICONS: Record<string, string> = {
	'update': 'pen',
	'delete': 'trash'
}

@Component({
	selector: 'app-action-button',
	standalone: true,
	imports: [TPipe],
	templateUrl: './action-button.component.html',
	styleUrl: './action-button.component.scss'
})
export class ActionButtonComponent implements OnInit {
	@Input() action!: string
	@Input() class?: string
	icon!: string

	ngOnInit(): void {
		this.icon = ICONS[this.action] || this.action
	}

}
