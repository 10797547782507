<form [formGroup]="form" (ngSubmit)="submit()" class="px-3">
  @for (element of elements; track element) {
    <div [hidden]="element.control.disabled">
      <label [for]="element.key" class="h6">{{ name + "." + element.key | t }}</label>

      @switch (element.component) {
        @case ("text") {
          <app-text-input [config]="element"></app-text-input>
        }
        @case ("number") {
          <app-number-input [config]="element"></app-number-input>
        }
        @case ("boolean") {
          <app-boolean-input [config]="element"></app-boolean-input>
        }
        @case ("enum") {
          <app-enum-input [config]="element"></app-enum-input>
        }
        @case ("array-enum") {
          <app-array-enum-input [config]="element"></app-array-enum-input>
        }
        @case ("ref") {
          <app-ref-input [config]="element"></app-ref-input>
        }
        @case ("object") {
          <app-object-input [config]="element"></app-object-input>
        }
        @case ("array-object") {
          <app-array-object-input [config]="element"></app-array-object-input>
        }
        @case ("array-string") {
          <app-array-string-input [config]="element"></app-array-string-input>
        }
      }

      @if (element.control.touched) {
        <div>
          @for (item of element.control.errors | keyvalue; track item) {
            <div>
              {{ "form.error." + item.key | t: item.value }}
            </div>
          }
        </div>
      }
    </div>
  }

  @if (!subform) {
    <button type="submit" [disabled]="disabled" class="btn btn-primary me-1 mb-1 mt-3">
      {{ "form.submit" | t }}
    </button>
  }
</form>
