export class ToastModel {
		public visible: boolean
		public message!: string
		public type: ToastType

		constructor(visible: boolean) {
			this.visible = visible
			this.type = ToastType.None
		}
}

export enum ToastType {
		Warning =	'warning',
		Error = 'error',
		Success = 'success',
		None = 'none'
}
