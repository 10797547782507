<div class="position-fixed bottom-0 start-50 mb-2 translate-middle-x d-block" style="z-index: 5">
  @for (toast of toastStack; track toast; let pos = $index) {
    <div
      class="toast show align-items-center text-white border-0 px-2 py-2 my-2"
      [class]="toast.type === 'success' ? 'text-bg-success' : toast.type === 'warning' ? 'text-bg-warning' : toast.type === 'error' ? 'text-bg-danger' : 'text-bg-dark'"
      [hidden]="!toast.visible"
      role="alert"
      data-bs-autohide="false"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div class="d-flex flex-between-center">
        <div [class]="class" class="toast-body">
          <strong class="d-flex flex-wrap">{{ toast.message }}</strong>
        </div>
        <div data-bs-theme="dark"><button class="btn-close me-2 m-auto" type="button" data-bs-dismiss="toast" aria-label="Close" (click)="close(pos)"></button></div>
      </div>
    </div>
  }
</div>
