import { Component, forwardRef } from '@angular/core'
import { AbstractControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms'
import { parsePhoneNumberWithError } from 'libphonenumber-js'
import { InputComponent } from '../input'

@Component({
	selector: 'app-text-input',
	templateUrl: './text-input.component.html',
	styleUrls: ['./text-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TextInputComponent),
			multi: true
		}
	],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule]
})
export class TextInputComponent<T extends Record<string, any>> extends InputComponent<T> {

	private types: Record<string, string> = {
		'email': 'email',
		'phone': 'tel',
		'password': 'password',
		'date-time': 'date'
	}
	protected type = 'text'

	override init(): void {
		this.type = this.types[this.config.format!] || 'text'

		this.config.control.valueChanges.subscribe(value => {
			if (this.config.format === 'date-time' && value && value.length != 10) {
				this.config.control.setValue(value.slice(0, 10))
			}

			if (!value && value !== undefined) {
				this.config.control.setValue(undefined)
			}
		})
	}

	override validators() {
		const validators = []

		if (this.config.minLength) {
			validators.push(Validators.minLength(this.config.minLength))
		}

		if (this.config.maxLength) {
			validators.push(Validators.maxLength(this.config.maxLength))
		}

		if (this.config.format === 'email') {
			validators.push(Validators.email)
		}

		if (this.config.format === 'phone') {
			validators.push(phoneValidator)
		}

		if (this.config.pattern) {
			validators.push(Validators.pattern(new RegExp(this.config.pattern)))
		}

		return validators
	}
}

function phoneValidator(control: AbstractControl): ValidationErrors | null {
	try {
		if (control.value) {
			const num = parsePhoneNumberWithError(control.value, 'FR')
			if (!num.isValid()) {
				throw new Error('invalid_phone_number')
			}
		}

		return null
	} catch (error) {
		return { phone: error.message }
	}
}
