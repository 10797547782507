import { Component } from '@angular/core'
import { TPipe } from '../../t'
import { EntityPageComponent } from '../entity-page/entity-page.component'

@Component({
	selector: 'app-admin-user-page',
	standalone: true,
	imports: [EntityPageComponent, TPipe],
	templateUrl: './admin-user-page.component.html',
	styleUrl: './admin-user-page.component.scss'
})
export class AdminUserPageComponent {

}
