import { Component, ViewChildren, forwardRef } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import schemas from '../../../../api/schemas/schemas'
import { SchemaObject } from '../../../schema.interface'
import { DynamicFormComponent } from '../../dynamic-form/dynamic-form.component'
import { InputComponent } from '../input'

@Component({
	selector: 'app-array-object-input',
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, forwardRef(() => DynamicFormComponent)],
	templateUrl: './array-object-input.component.html',
	styleUrl: './array-object-input.component.scss'
})
export class ArrayObjectInputComponent<T extends Record<string, any>> extends InputComponent<T> {
	value: Record<string, any>[] = []
	schema!: SchemaObject<T>
	@ViewChildren(forwardRef(() => DynamicFormComponent)) forms!: DynamicFormComponent<Record<string, any>>[]

	override init() {
		this.schema = schemas[this.config.items!.$ref!]

		this.config.control.valueChanges.subscribe(value => {
			if (value !== this.value) {
				this.value = <T[string]>(value || [])
				this.config.control.setValue(<T[string]>this.value)
			}
		})

		if (!Array.isArray(this.config.control.value)) {
			this.config.control.setValue(<T[string]>[])
		}
	}

	override validate() {
		let result = true

		for (const form of this.forms) {
			if (!form.validate()) {
				result = false
			}
		}

		return result
	}

	add() {
		this.value.push({})
	}

	up(index: number) {
		if (index > 0) {
			[this.value[index - 1], this.value[index]] = [this.value[index]!, this.value[index - 1]!]
		}
	}

	down(index: number) {
		if (index < this.value.length - 1) {
			[this.value[index], this.value[index + 1]] = [this.value[index + 1]!, this.value[index]!]
		}
	}

	delete(index: number) {
		this.value.splice(index, 1)
	}

}
