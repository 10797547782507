@if (provider) {
  <div class="card">
    <div class="card-header">
      <h2 class="me-5">{{ provider.name }}</h2>
      <ul class="nav nav-tabs">
        <li class="nav-item">
          <a class="nav-link" routerLink="sitekey" routerLinkActive="active">{{ "sitekeys" | t }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="remuneration" routerLinkActive="active">{{ "remuneration" | t }}</a>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <router-outlet></router-outlet>
    </div>
  </div>
}
