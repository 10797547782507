/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { appControllerRoot } from '../fn/operations/app-controller-root';
import { AppControllerRoot$Params } from '../fn/operations/app-controller-root';
import { Area } from '../models/area';
import { areaControllerCreate } from '../fn/operations/area-controller-create';
import { AreaControllerCreate$Params } from '../fn/operations/area-controller-create';
import { areaControllerDelete } from '../fn/operations/area-controller-delete';
import { AreaControllerDelete$Params } from '../fn/operations/area-controller-delete';
import { areaControllerFind } from '../fn/operations/area-controller-find';
import { AreaControllerFind$Params } from '../fn/operations/area-controller-find';
import { areaControllerRead } from '../fn/operations/area-controller-read';
import { AreaControllerRead$Params } from '../fn/operations/area-controller-read';
import { areaControllerUpdate } from '../fn/operations/area-controller-update';
import { AreaControllerUpdate$Params } from '../fn/operations/area-controller-update';
import { AreaPage } from '../models/area-page';
import { authControllerExistingToken } from '../fn/operations/auth-controller-existing-token';
import { AuthControllerExistingToken$Params } from '../fn/operations/auth-controller-existing-token';
import { authControllerForgotPassword } from '../fn/operations/auth-controller-forgot-password';
import { AuthControllerForgotPassword$Params } from '../fn/operations/auth-controller-forgot-password';
import { authControllerLogin } from '../fn/operations/auth-controller-login';
import { AuthControllerLogin$Params } from '../fn/operations/auth-controller-login';
import { authControllerLogout } from '../fn/operations/auth-controller-logout';
import { AuthControllerLogout$Params } from '../fn/operations/auth-controller-logout';
import { authControllerMe } from '../fn/operations/auth-controller-me';
import { AuthControllerMe$Params } from '../fn/operations/auth-controller-me';
import { authControllerResetPassword } from '../fn/operations/auth-controller-reset-password';
import { AuthControllerResetPassword$Params } from '../fn/operations/auth-controller-reset-password';
import { Client } from '../models/client';
import { clientControllerCreate } from '../fn/operations/client-controller-create';
import { ClientControllerCreate$Params } from '../fn/operations/client-controller-create';
import { clientControllerDelete } from '../fn/operations/client-controller-delete';
import { ClientControllerDelete$Params } from '../fn/operations/client-controller-delete';
import { clientControllerFind } from '../fn/operations/client-controller-find';
import { ClientControllerFind$Params } from '../fn/operations/client-controller-find';
import { clientControllerRead } from '../fn/operations/client-controller-read';
import { ClientControllerRead$Params } from '../fn/operations/client-controller-read';
import { clientControllerUpdate } from '../fn/operations/client-controller-update';
import { ClientControllerUpdate$Params } from '../fn/operations/client-controller-update';
import { ClientPage } from '../models/client-page';
import { leadControllerCreate } from '../fn/operations/lead-controller-create';
import { LeadControllerCreate$Params } from '../fn/operations/lead-controller-create';
import { Order } from '../models/order';
import { orderControllerCreate } from '../fn/operations/order-controller-create';
import { OrderControllerCreate$Params } from '../fn/operations/order-controller-create';
import { orderControllerDelete } from '../fn/operations/order-controller-delete';
import { OrderControllerDelete$Params } from '../fn/operations/order-controller-delete';
import { orderControllerFind } from '../fn/operations/order-controller-find';
import { OrderControllerFind$Params } from '../fn/operations/order-controller-find';
import { orderControllerRead } from '../fn/operations/order-controller-read';
import { OrderControllerRead$Params } from '../fn/operations/order-controller-read';
import { orderControllerUpdate } from '../fn/operations/order-controller-update';
import { OrderControllerUpdate$Params } from '../fn/operations/order-controller-update';
import { OrderPage } from '../models/order-page';
import { Provider } from '../models/provider';
import { providerControllerCreate } from '../fn/operations/provider-controller-create';
import { ProviderControllerCreate$Params } from '../fn/operations/provider-controller-create';
import { providerControllerDelete } from '../fn/operations/provider-controller-delete';
import { ProviderControllerDelete$Params } from '../fn/operations/provider-controller-delete';
import { providerControllerFind } from '../fn/operations/provider-controller-find';
import { ProviderControllerFind$Params } from '../fn/operations/provider-controller-find';
import { providerControllerRead } from '../fn/operations/provider-controller-read';
import { ProviderControllerRead$Params } from '../fn/operations/provider-controller-read';
import { providerControllerReadRemuneration } from '../fn/operations/provider-controller-read-remuneration';
import { ProviderControllerReadRemuneration$Params } from '../fn/operations/provider-controller-read-remuneration';
import { providerControllerUpdate } from '../fn/operations/provider-controller-update';
import { ProviderControllerUpdate$Params } from '../fn/operations/provider-controller-update';
import { providerControllerUpdateRemuneration } from '../fn/operations/provider-controller-update-remuneration';
import { ProviderControllerUpdateRemuneration$Params } from '../fn/operations/provider-controller-update-remuneration';
import { ProviderPage } from '../models/provider-page';
import { ProviderRemuneration } from '../models/provider-remuneration';
import { RootResponse } from '../models/root-response';
import { Sitekey } from '../models/sitekey';
import { sitekeyControllerCreate } from '../fn/operations/sitekey-controller-create';
import { SitekeyControllerCreate$Params } from '../fn/operations/sitekey-controller-create';
import { sitekeyControllerDelete } from '../fn/operations/sitekey-controller-delete';
import { SitekeyControllerDelete$Params } from '../fn/operations/sitekey-controller-delete';
import { sitekeyControllerFind } from '../fn/operations/sitekey-controller-find';
import { SitekeyControllerFind$Params } from '../fn/operations/sitekey-controller-find';
import { sitekeyControllerRead } from '../fn/operations/sitekey-controller-read';
import { SitekeyControllerRead$Params } from '../fn/operations/sitekey-controller-read';
import { sitekeyControllerUpdate } from '../fn/operations/sitekey-controller-update';
import { SitekeyControllerUpdate$Params } from '../fn/operations/sitekey-controller-update';
import { SitekeyPage } from '../models/sitekey-page';
import { User } from '../models/user';
import { userControllerCreate } from '../fn/operations/user-controller-create';
import { UserControllerCreate$Params } from '../fn/operations/user-controller-create';
import { userControllerDelete } from '../fn/operations/user-controller-delete';
import { UserControllerDelete$Params } from '../fn/operations/user-controller-delete';
import { userControllerFind } from '../fn/operations/user-controller-find';
import { UserControllerFind$Params } from '../fn/operations/user-controller-find';
import { userControllerRead } from '../fn/operations/user-controller-read';
import { UserControllerRead$Params } from '../fn/operations/user-controller-read';
import { userControllerUpdate } from '../fn/operations/user-controller-update';
import { UserControllerUpdate$Params } from '../fn/operations/user-controller-update';
import { userControllerUpdateUserPassword } from '../fn/operations/user-controller-update-user-password';
import { UserControllerUpdateUserPassword$Params } from '../fn/operations/user-controller-update-user-password';
import { UserPage } from '../models/user-page';


class Controller {
	constructor(
		protected readonly api: ApiService,
		protected readonly http: HttpClient
	) {}
}

class AppController extends Controller {
	
  /** Path part for operation `appControllerRoot()` */
  static readonly AppControllerRootPath = '/';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `appControllerRoot()` instead.
   *
   * This method doesn't expect any request body.
   */
  root$Response(params?: AppControllerRoot$Params, context?: HttpContext): Observable<StrictHttpResponse<RootResponse>> {
    return appControllerRoot(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `appControllerRoot$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  root(params?: AppControllerRoot$Params, context?: HttpContext): Observable<RootResponse> {

    return this.root$Response(params,context).pipe(
      map((r: StrictHttpResponse<RootResponse>): RootResponse => r.body)
    );
  }



}
class AuthController extends Controller {
	
  /** Path part for operation `authControllerLogin()` */
  static readonly AuthControllerLoginPath = '/auth/login';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogin()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login$Response(params: AuthControllerLogin$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return authControllerLogin(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogin$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  login(params: AuthControllerLogin$Params, context?: HttpContext): Observable<User> {

    return this.login$Response(params,context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }



  /** Path part for operation `authControllerLogout()` */
  static readonly AuthControllerLogoutPath = '/auth/logout';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerLogout()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout$Response(params?: AuthControllerLogout$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return authControllerLogout(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerLogout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  logout(params?: AuthControllerLogout$Params, context?: HttpContext): Observable<{
}> {

    return this.logout$Response(params,context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }



  /** Path part for operation `authControllerMe()` */
  static readonly AuthControllerMePath = '/auth/me';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerMe()` instead.
   *
   * This method doesn't expect any request body.
   */
  me$Response(params?: AuthControllerMe$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return authControllerMe(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerMe$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  me(params?: AuthControllerMe$Params, context?: HttpContext): Observable<User> {

    return this.me$Response(params,context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }



  /** Path part for operation `authControllerExistingToken()` */
  static readonly AuthControllerExistingTokenPath = '/auth/token';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerExistingToken()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  existingToken$Response(params: AuthControllerExistingToken$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerExistingToken(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerExistingToken$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  existingToken(params: AuthControllerExistingToken$Params, context?: HttpContext): Observable<void> {

    return this.existingToken$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `authControllerForgotPassword()` */
  static readonly AuthControllerForgotPasswordPath = '/auth/forgotpassword';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerForgotPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword$Response(params: AuthControllerForgotPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerForgotPassword(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerForgotPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  forgotPassword(params: AuthControllerForgotPassword$Params, context?: HttpContext): Observable<void> {

    return this.forgotPassword$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `authControllerResetPassword()` */
  static readonly AuthControllerResetPasswordPath = '/auth/resetpassword';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authControllerResetPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword$Response(params: AuthControllerResetPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return authControllerResetPassword(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authControllerResetPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  resetPassword(params: AuthControllerResetPassword$Params, context?: HttpContext): Observable<void> {

    return this.resetPassword$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



}
class UserController extends Controller {
	
  /** Path part for operation `userControllerUpdateUserPassword()` */
  static readonly UserControllerUpdateUserPasswordPath = '/user/updatepassword';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdateUserPassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserPassword$Response(params: UserControllerUpdateUserPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userControllerUpdateUserPassword(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdateUserPassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserPassword(params: UserControllerUpdateUserPassword$Params, context?: HttpContext): Observable<void> {

    return this.updateUserPassword$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `userControllerCreate()` */
  static readonly UserControllerCreatePath = '/user';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: UserControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userControllerCreate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: UserControllerCreate$Params, context?: HttpContext): Observable<void> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `userControllerFind()` */
  static readonly UserControllerFindPath = '/user/find';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerFind()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find$Response(params: UserControllerFind$Params, context?: HttpContext): Observable<StrictHttpResponse<UserPage>> {
    return userControllerFind(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerFind$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find(params: UserControllerFind$Params, context?: HttpContext): Observable<UserPage> {

    return this.find$Response(params,context).pipe(
      map((r: StrictHttpResponse<UserPage>): UserPage => r.body)
    );
  }



  /** Path part for operation `userControllerRead()` */
  static readonly UserControllerReadPath = '/user/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  read$Response(params: UserControllerRead$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return userControllerRead(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  read(params: UserControllerRead$Params, context?: HttpContext): Observable<User> {

    return this.read$Response(params,context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }



  /** Path part for operation `userControllerUpdate()` */
  static readonly UserControllerUpdatePath = '/user/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: UserControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userControllerUpdate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: UserControllerUpdate$Params, context?: HttpContext): Observable<void> {

    return this.update$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `userControllerDelete()` */
  static readonly UserControllerDeletePath = '/user/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: UserControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userControllerDelete(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: UserControllerDelete$Params, context?: HttpContext): Observable<void> {

    return this.delete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



}
class ClientController extends Controller {
	
  /** Path part for operation `clientControllerCreate()` */
  static readonly ClientControllerCreatePath = '/client';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: ClientControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clientControllerCreate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: ClientControllerCreate$Params, context?: HttpContext): Observable<void> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `clientControllerFind()` */
  static readonly ClientControllerFindPath = '/client/find';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientControllerFind()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find$Response(params: ClientControllerFind$Params, context?: HttpContext): Observable<StrictHttpResponse<ClientPage>> {
    return clientControllerFind(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientControllerFind$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find(params: ClientControllerFind$Params, context?: HttpContext): Observable<ClientPage> {

    return this.find$Response(params,context).pipe(
      map((r: StrictHttpResponse<ClientPage>): ClientPage => r.body)
    );
  }



  /** Path part for operation `clientControllerRead()` */
  static readonly ClientControllerReadPath = '/client/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  read$Response(params: ClientControllerRead$Params, context?: HttpContext): Observable<StrictHttpResponse<Client>> {
    return clientControllerRead(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  read(params: ClientControllerRead$Params, context?: HttpContext): Observable<Client> {

    return this.read$Response(params,context).pipe(
      map((r: StrictHttpResponse<Client>): Client => r.body)
    );
  }



  /** Path part for operation `clientControllerUpdate()` */
  static readonly ClientControllerUpdatePath = '/client/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: ClientControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clientControllerUpdate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: ClientControllerUpdate$Params, context?: HttpContext): Observable<void> {

    return this.update$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `clientControllerDelete()` */
  static readonly ClientControllerDeletePath = '/client/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `clientControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: ClientControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return clientControllerDelete(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `clientControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: ClientControllerDelete$Params, context?: HttpContext): Observable<void> {

    return this.delete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



}
class AreaController extends Controller {
	
  /** Path part for operation `areaControllerCreate()` */
  static readonly AreaControllerCreatePath = '/area';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areaControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: AreaControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return areaControllerCreate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `areaControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: AreaControllerCreate$Params, context?: HttpContext): Observable<void> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `areaControllerFind()` */
  static readonly AreaControllerFindPath = '/area/find';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areaControllerFind()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find$Response(params: AreaControllerFind$Params, context?: HttpContext): Observable<StrictHttpResponse<AreaPage>> {
    return areaControllerFind(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `areaControllerFind$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find(params: AreaControllerFind$Params, context?: HttpContext): Observable<AreaPage> {

    return this.find$Response(params,context).pipe(
      map((r: StrictHttpResponse<AreaPage>): AreaPage => r.body)
    );
  }



  /** Path part for operation `areaControllerRead()` */
  static readonly AreaControllerReadPath = '/area/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areaControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  read$Response(params: AreaControllerRead$Params, context?: HttpContext): Observable<StrictHttpResponse<Area>> {
    return areaControllerRead(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `areaControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  read(params: AreaControllerRead$Params, context?: HttpContext): Observable<Area> {

    return this.read$Response(params,context).pipe(
      map((r: StrictHttpResponse<Area>): Area => r.body)
    );
  }



  /** Path part for operation `areaControllerUpdate()` */
  static readonly AreaControllerUpdatePath = '/area/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areaControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: AreaControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return areaControllerUpdate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `areaControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: AreaControllerUpdate$Params, context?: HttpContext): Observable<void> {

    return this.update$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `areaControllerDelete()` */
  static readonly AreaControllerDeletePath = '/area/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `areaControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: AreaControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return areaControllerDelete(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `areaControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: AreaControllerDelete$Params, context?: HttpContext): Observable<void> {

    return this.delete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



}
class ProviderController extends Controller {
	
  /** Path part for operation `providerControllerReadRemuneration()` */
  static readonly ProviderControllerReadRemunerationPath = '/provider/{id}/remuneration';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `providerControllerReadRemuneration()` instead.
   *
   * This method doesn't expect any request body.
   */
  readRemuneration$Response(params: ProviderControllerReadRemuneration$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ProviderRemuneration>>> {
    return providerControllerReadRemuneration(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `providerControllerReadRemuneration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readRemuneration(params: ProviderControllerReadRemuneration$Params, context?: HttpContext): Observable<Array<ProviderRemuneration>> {

    return this.readRemuneration$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ProviderRemuneration>>): Array<ProviderRemuneration> => r.body)
    );
  }



  /** Path part for operation `providerControllerUpdateRemuneration()` */
  static readonly ProviderControllerUpdateRemunerationPath = '/provider/{id}/remuneration';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `providerControllerUpdateRemuneration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRemuneration$Response(params: ProviderControllerUpdateRemuneration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return providerControllerUpdateRemuneration(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `providerControllerUpdateRemuneration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRemuneration(params: ProviderControllerUpdateRemuneration$Params, context?: HttpContext): Observable<void> {

    return this.updateRemuneration$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `providerControllerCreate()` */
  static readonly ProviderControllerCreatePath = '/provider';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `providerControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: ProviderControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return providerControllerCreate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `providerControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: ProviderControllerCreate$Params, context?: HttpContext): Observable<void> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `providerControllerFind()` */
  static readonly ProviderControllerFindPath = '/provider/find';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `providerControllerFind()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find$Response(params: ProviderControllerFind$Params, context?: HttpContext): Observable<StrictHttpResponse<ProviderPage>> {
    return providerControllerFind(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `providerControllerFind$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find(params: ProviderControllerFind$Params, context?: HttpContext): Observable<ProviderPage> {

    return this.find$Response(params,context).pipe(
      map((r: StrictHttpResponse<ProviderPage>): ProviderPage => r.body)
    );
  }



  /** Path part for operation `providerControllerRead()` */
  static readonly ProviderControllerReadPath = '/provider/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `providerControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  read$Response(params: ProviderControllerRead$Params, context?: HttpContext): Observable<StrictHttpResponse<Provider>> {
    return providerControllerRead(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `providerControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  read(params: ProviderControllerRead$Params, context?: HttpContext): Observable<Provider> {

    return this.read$Response(params,context).pipe(
      map((r: StrictHttpResponse<Provider>): Provider => r.body)
    );
  }



  /** Path part for operation `providerControllerUpdate()` */
  static readonly ProviderControllerUpdatePath = '/provider/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `providerControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: ProviderControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return providerControllerUpdate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `providerControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: ProviderControllerUpdate$Params, context?: HttpContext): Observable<void> {

    return this.update$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `providerControllerDelete()` */
  static readonly ProviderControllerDeletePath = '/provider/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `providerControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: ProviderControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return providerControllerDelete(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `providerControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: ProviderControllerDelete$Params, context?: HttpContext): Observable<void> {

    return this.delete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



}
class SitekeyController extends Controller {
	
  /** Path part for operation `sitekeyControllerCreate()` */
  static readonly SitekeyControllerCreatePath = '/sitekey';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sitekeyControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: SitekeyControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sitekeyControllerCreate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sitekeyControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: SitekeyControllerCreate$Params, context?: HttpContext): Observable<void> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `sitekeyControllerFind()` */
  static readonly SitekeyControllerFindPath = '/sitekey/find';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sitekeyControllerFind()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find$Response(params: SitekeyControllerFind$Params, context?: HttpContext): Observable<StrictHttpResponse<SitekeyPage>> {
    return sitekeyControllerFind(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sitekeyControllerFind$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find(params: SitekeyControllerFind$Params, context?: HttpContext): Observable<SitekeyPage> {

    return this.find$Response(params,context).pipe(
      map((r: StrictHttpResponse<SitekeyPage>): SitekeyPage => r.body)
    );
  }



  /** Path part for operation `sitekeyControllerRead()` */
  static readonly SitekeyControllerReadPath = '/sitekey/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sitekeyControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  read$Response(params: SitekeyControllerRead$Params, context?: HttpContext): Observable<StrictHttpResponse<Sitekey>> {
    return sitekeyControllerRead(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sitekeyControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  read(params: SitekeyControllerRead$Params, context?: HttpContext): Observable<Sitekey> {

    return this.read$Response(params,context).pipe(
      map((r: StrictHttpResponse<Sitekey>): Sitekey => r.body)
    );
  }



  /** Path part for operation `sitekeyControllerUpdate()` */
  static readonly SitekeyControllerUpdatePath = '/sitekey/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sitekeyControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: SitekeyControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sitekeyControllerUpdate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sitekeyControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: SitekeyControllerUpdate$Params, context?: HttpContext): Observable<void> {

    return this.update$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `sitekeyControllerDelete()` */
  static readonly SitekeyControllerDeletePath = '/sitekey/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sitekeyControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: SitekeyControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sitekeyControllerDelete(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sitekeyControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: SitekeyControllerDelete$Params, context?: HttpContext): Observable<void> {

    return this.delete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



}
class OrderController extends Controller {
	
  /** Path part for operation `orderControllerCreate()` */
  static readonly OrderControllerCreatePath = '/order';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: OrderControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return orderControllerCreate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: OrderControllerCreate$Params, context?: HttpContext): Observable<void> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `orderControllerFind()` */
  static readonly OrderControllerFindPath = '/order/find';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerFind()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find$Response(params: OrderControllerFind$Params, context?: HttpContext): Observable<StrictHttpResponse<OrderPage>> {
    return orderControllerFind(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerFind$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  find(params: OrderControllerFind$Params, context?: HttpContext): Observable<OrderPage> {

    return this.find$Response(params,context).pipe(
      map((r: StrictHttpResponse<OrderPage>): OrderPage => r.body)
    );
  }



  /** Path part for operation `orderControllerRead()` */
  static readonly OrderControllerReadPath = '/order/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  read$Response(params: OrderControllerRead$Params, context?: HttpContext): Observable<StrictHttpResponse<Order>> {
    return orderControllerRead(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  read(params: OrderControllerRead$Params, context?: HttpContext): Observable<Order> {

    return this.read$Response(params,context).pipe(
      map((r: StrictHttpResponse<Order>): Order => r.body)
    );
  }



  /** Path part for operation `orderControllerUpdate()` */
  static readonly OrderControllerUpdatePath = '/order/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update$Response(params: OrderControllerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return orderControllerUpdate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  update(params: OrderControllerUpdate$Params, context?: HttpContext): Observable<void> {

    return this.update$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



  /** Path part for operation `orderControllerDelete()` */
  static readonly OrderControllerDeletePath = '/order/{id}';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orderControllerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: OrderControllerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return orderControllerDelete(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orderControllerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: OrderControllerDelete$Params, context?: HttpContext): Observable<void> {

    return this.delete$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



}
class LeadController extends Controller {
	
  /** Path part for operation `leadControllerCreate()` */
  static readonly LeadControllerCreatePath = '/lead';



  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `leadControllerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: LeadControllerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return leadControllerCreate(this.http, this.api.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `leadControllerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: LeadControllerCreate$Params, context?: HttpContext): Observable<void> {

    return this.create$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }



}

@Injectable({ providedIn: 'root' })
export class ApiService extends BaseService {

	public readonly app = new AppController(this, this.http)
public readonly auth = new AuthController(this, this.http)
public readonly user = new UserController(this, this.http)
public readonly client = new ClientController(this, this.http)
public readonly area = new AreaController(this, this.http)
public readonly provider = new ProviderController(this, this.http)
public readonly sitekey = new SitekeyController(this, this.http)
public readonly order = new OrderController(this, this.http)
public readonly lead = new LeadController(this, this.http)

  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }
}
