import { Pipe, PipeTransform } from '@angular/core'
import { isObjectLike } from 'lodash'

export function label(obj: any) {
	if (!isObjectLike(obj)) {
		return obj
	}

	return obj.label || obj.displayName || obj.name || obj.title || obj.email || obj._id || obj.id || ''
}

@Pipe({
	name: 'label',
	standalone: true
})
export class LabelPipe implements PipeTransform {

	transform(value: any): string {
		return label(value)
	}

}
