
import { Component } from '@angular/core'
import { RouterLink, RouterLinkActive } from '@angular/router'
import { t } from '../t'

@Component({
	selector: 'app-menu',
	templateUrl: './menu.component.html',
	styleUrls: ['./menu.component.scss'],
	standalone: true,
	imports: [RouterLink, RouterLinkActive]
})
export class MenuComponent {
	public data = [
		{ title: t('menu.leads.title'), href: '/lead' },
		{ title: t('menu.clients.title'), href: '/client' },
		{ title: t('menu.providers.title'), href: '/provider' },
		{ title: t('menu.areas.title'), href: '/area' },
		{ title: t('menu.users.title'), href: '/user' }
	]
}
