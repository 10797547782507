@if (data) {
  <div data-list>
    <div class="row justify-content-between g-0">
      <div class="col-auto col-sm-2">
        <div class="input-group">
          <input
            class="form-control form-control-sm shadow-none search"
            type="search"
            placeholder="{{ 'search' | t }}"
            aria-label="search"
            [(ngModel)]="params.search"
            (ngModelChange)="search()"
          />
          <div class="input-group-text bg-transparent">
            <i class="bi-search fs-9 text-600"></i>
          </div>
        </div>
      </div>
      <div class="col-auto d-flex justify-content-center mb-3">
        <button
          class="btn btn-sm btn-falcon-default me-1"
          type="button"
          title="firstPage"
          data-list-pagination="firstPage"
          [class.disabled]="data.page === 1"
          [disabled]="data.page === 1"
          (click)="page(1)"
        >
          <i class="bi-chevron-double-left"></i>
        </button>
        <button
          class="btn btn-sm btn-falcon-default me-1"
          type="button"
          title="Previous"
          data-list-pagination="prev"
          [class.disabled]="data.page === 1"
          [disabled]="data.page === 1"
          (click)="page(data.page - 1)"
        >
          <i class="bi-chevron-left"></i>
        </button>
        <ul class="pagination mb-0">
          @for (i of pages; track i) {
            <li [class.active]="i === data.page">
              <button class="page" type="button" (click)="page(i)">
                {{ i }}
              </button>
            </li>
          }
        </ul>
        <button
          class="btn btn-sm btn-falcon-default ms-1"
          type="button"
          title="Next"
          data-list-pagination="next"
          [class.disabled]="data.page === data.lastPage"
          [disabled]="data.page === data.lastPage"
          (click)="page(data.page + 1)"
        >
          <i class="bi-chevron-right"></i>
        </button>
        <button
          class="btn btn-sm btn-falcon-default me-1"
          type="button"
          title="lastPage"
          data-list-pagination="lastPage"
          [class.disabled]="data.page === data.lastPage"
          [disabled]="data.page === data.lastPage"
          (click)="page(data.lastPage)"
        >
          <i class="bi-chevron-double-right"></i>
        </button>
      </div>
    </div>
    <div class="table-responsive scrollbar">
      <table class="table table-bordered table-striped fs-9 mb-0">
        <thead class="bg-200">
          <tr>
            @for (property of columns; track property) {
              <th
                class="text-900"
                data-sort
                [class.sort]="schema.properties![property]!.sortable"
                [class.asc]="schema.properties![property]!.sortable && data.sort === property && data.order === 'asc'"
                [class.desc]="schema.properties![property]!.sortable && data.sort === property && data.order === 'desc'"
                [style.width]="90 / columns!.length + '%'"
                (click)="sort(property)"
              >
                {{ name + "." + property | t }}
              </th>
            }
            <th class="text-900" style="width: 10%">
              {{ "table.actions" | t }}
            </th>
          </tr>
        </thead>
        <tbody class="list">
          @for (item of data!.items; track item) {
            <tr>
              @for (property of columns; track property; let i = $index) {
                <td>
                  @if (i === 0 && link) {
                    <a [routerLink]="computeLink(item)">
                      <app-value type="{{ name }}" name="{{ property }}" [schema]="schema.properties![property]!" [value]="item[property]"></app-value>
                    </a>
                  }
                  @if (!(i === 0 && link)) {
                    <app-value type="{{ name }}" name="{{ property }}" [schema]="schema.properties![property]!" [value]="item[property]"></app-value>
                  }
                </td>
              }
              <td>
                @for (action of actions; track action) {
                  <app-action-button action="{{ action }}" (click)="emitAction(action, item)" class="me-1"></app-action-button>
                }
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <button
        class="btn btn-sm btn-falcon-default me-1"
        type="button"
        title="firstPage"
        data-list-pagination="firstPage"
        [class.disabled]="data.page === 1"
        [disabled]="data.page === 1"
        (click)="page(1)"
      >
        <i class="bi-chevron-double-left"></i>
      </button>
      <button
        class="btn btn-sm btn-falcon-default me-1"
        type="button"
        title="Previous"
        data-list-pagination="prev"
        [class.disabled]="data.page === 1"
        [disabled]="data.page === 1"
        (click)="page(data.page - 1)"
      >
        <i class="bi-chevron-left"></i>
      </button>
      <ul class="pagination mb-0">
        @for (i of pages; track i) {
          <li [class.active]="i === data.page">
            <button class="page" type="button" (click)="page(i)">
              {{ i }}
            </button>
          </li>
        }
      </ul>
      <button
        class="btn btn-sm btn-falcon-default ms-1"
        type="button"
        title="Next"
        data-list-pagination="next"
        [class.disabled]="data.page === data.lastPage"
        [disabled]="data.page === data.lastPage"
        (click)="page(data.page + 1)"
      >
        <i class="bi-chevron-right"></i>
      </button>
      <button
        class="btn btn-sm btn-falcon-default me-1"
        type="button"
        title="lastPage"
        data-list-pagination="lastPage"
        [class.disabled]="data.page === data.lastPage"
        [disabled]="data.page === data.lastPage"
        (click)="page(data.lastPage)"
      >
        <i class="bi-chevron-double-right"></i>
      </button>
    </div>
  </div>
}
