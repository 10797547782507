<link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
<section class="h-100">
  <header class="container h-100">
    <div class="d-flex align-items-center justify-content-center h-100">
      <div class="d-flex flex-column">
        <div class="card text-center" style="width: 700px">
          <div class="card-header h5 text-white bg-primary">{{ "reset-password.title" | t }}</div>
          <!-- Start of structural guidelines for tokenError -->
          @if (!tokenError) {
            <div class="card-body px-6">
              <p [innerHTML]="message"></p>
              @if (!formSended) {
                <label class="form-label" for="card-reset-password">{{ "reset-password.rules" | t }}</label>
              }
              @if (passwordError) {
                <div class="alert alert-danger alert-dismissible fade show m-3">
                  @if (passwordError === 1) {
                    <span>{{ "reset-password.missing" | t }}</span>
                  }
                  @if (passwordError === 2) {
                    <span>{{ "reset-password.mismatch" | t }}</span>
                  }
                  @if (passwordError === 3) {
                    <span>{{ "reset-password.invalid" | t }}</span>
                  }
                </div>
              }
              <!-- Start of structural guidelines for formSended -->
              @if (!formSended) {
                <div class="card-body px-6">
                  <div class="input-group {{ borderClassNewPassword }} p-1 mb-3">
                    <input
                      class="form-control"
                      (keyup)="borderChange()"
                      type="{{ passwordType }}"
                      placeholder="{{ 'reset-password.new' | t }}"
                      type="password"
                      id="newPassword"
                      [(ngModel)]="newPassword"
                      name="newPassword"
                      required
                    />
                    <a class="pe-auto">
                      <span class="input-group-text" (click)="hideSeekPassword()">
                        <span class="material-icons text-secondary fs-4">{{ visiblity }}</span>
                      </span>
                    </a>
                  </div>
                  <div class="input-group {{ borderClassConfirmPassword }} p-1 mb-3">
                    <input
                      class="form-control"
                      (keyup)="borderChange()"
                      type="{{ passwordType }}"
                      placeholder="{{ 'reset-password.confirm' | t }}"
                      type="password"
                      id="newPasswordConfirm"
                      [(ngModel)]="newPasswordConfirm"
                      name="newPasswordConfirm"
                      required
                    />
                    <a class="pe-auto">
                      <span class="input-group-text" (click)="hideSeekPassword()">
                        <span class="material-icons text-secondary fs-4">{{ visiblity }}</span>
                      </span>
                    </a>
                  </div>
                </div>
              }
              <!-- End of structural guidelines for formSended -->
              @if (!formSended) {
                <button (click)="resetPassword()" class="btn btn-primary w-100 mb-3">Valider</button>
              }
              <div class="d-flex justify-content-between">
                <div class="col align-self-end">
                  <a href="/login">{{ "reset-password.back-home" | t }}</a>
                </div>
              </div>
            </div>
          }
          <!-- End of structural guidelines for tokenError -->
          @if (tokenError) {
            <div class="card-body px-5">
              <div class="alert alert-danger alert-dismissible fade show">
                {{ "reset-password.error" | t }}
              </div>
              <div class="d-flex justify-content-between mt-4">
                <div class="col align-self-end">
                  <a href="/public/forgotpassword">{{ "reset-password.back" | t }}</a>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  </header>
</section>
