import { Component } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ApiService } from '../../../../api/services'
import { CrudController } from '../../../crud-controller.interface'
import { LabelPipe } from '../../../util'
import { InputComponent } from '../input'

@Component({
	selector: 'app-ref-input',
	templateUrl: './ref-input.component.html',
	styleUrls: ['./ref-input.component.scss'],
	providers: [],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, LabelPipe]
})
export class RefInputComponent<T extends Record<string, any>> extends InputComponent<T> {

	constructor(private api: ApiService) {
		super()
	}

	options: any[] = []

	override init(): void {
		if (!this.config.entity || this.config.control.disabled) {
			return
		}

		const entity = this.config.entity!
		const controller = <CrudController<any>>(<any>this.api)[entity.charAt(0).toLowerCase() + entity.slice(1)]

		controller.find({ body: { limit: 100 }}).subscribe(data => {
			this.options = data.items
		})
	}

}
