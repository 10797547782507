import { JsonPipe } from '@angular/common'
import { Component } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { TPipe } from '../../../t'
import { InputComponent } from '../input'

@Component({
	selector: 'app-array-string-input',
	templateUrl: './array-string-input.component.html',
	styleUrls: ['./array-string-input.component.scss'],
	standalone: true,
	imports: [FormsModule, ReactiveFormsModule, JsonPipe, TPipe]
})
export class ArrayStringInputComponent<T extends Record<string, any>> extends InputComponent<T> {
	value: string[] = []

	override init() {
		this.config.control.valueChanges.subscribe(value => {
			if (value !== this.value) {
				this.value = <T[string]>(value || [])
				this.config.control.setValue(<T[string]>this.value)
			}
		})

		if (!Array.isArray(this.config.control.value)) {
			this.config.control.setValue(<T[string]>[])
		}
	}

	add() {
		this.value.push('')
	}

	up(index: number) {
		if (index > 0) {
			[this.value[index - 1], this.value[index]] = [this.value[index]!, this.value[index - 1]!]
		}
	}

	down(index: number) {
		if (index < this.value.length - 1) {
			[this.value[index], this.value[index + 1]] = [this.value[index + 1]!, this.value[index]!]
		}
	}

	delete(index: number) {
		this.value.splice(index, 1)
	}
}
