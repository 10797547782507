import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ApiService } from '../../api/services'
import { t, TPipe } from '../t'
import { FormsModule } from '@angular/forms'


@Component({
	selector: 'app-resetpassword',
	templateUrl: './resetpassword.component.html',
	standalone: true,
	imports: [FormsModule, TPipe]
})
export class ResetpasswordComponent implements OnInit {
	newPassword = ''
	newPasswordConfirm = ''
	tokenId : any
	formSended! :boolean
	message = 'Veuillez renseigner votre nouveau mot de passe puis le confirmer'
	passwordError! : number
	tokenError! : boolean
	visiblity = 'visibility_off'
	passwordType = 'password'
	borderClassNewPassword = ''
	borderClassConfirmPassword = ''

	constructor (
		private route: ActivatedRoute,
		private readonly api: ApiService
	) {}

	ngOnInit(): void {
		this.tokenId =  this.route.snapshot.paramMap.get('token')
		this.api.auth.existingToken({ body: {
			tokenId: this.tokenId
		}}).subscribe({
			error: () => {
				this.tokenError = true
			}
		})
	}

	async resetPassword() {
		if (!this.newPassword || !this.newPasswordConfirm ) {
			this.passwordError = 1
			return
		}

		const isValidNewPassword = this.validatePassword(this.newPassword)
		const isValidConfirmPassword = this.validatePassword(this.newPassword)
		if (!isValidNewPassword || !isValidConfirmPassword ) {
			this.passwordError = 3
			return
		}

		if (this.newPassword !== this.newPasswordConfirm) {
			this.passwordError = 2
			return
		}

		this.api.auth.resetPassword({ body:
		{
			newPassword: this.newPassword,
			tokenId: this.tokenId
		}}).subscribe({
			next: () => {
				this.passwordError = 0
				this.formSended = true
				this.setMessage(this.formSended)
			},
			error: () => {
				this.tokenError = true
			}
		})
	}

	setMessage(formSended : boolean) {
		if (formSended) this.message = t('reset-password.success')
	}

	validatePassword(password : string) {

		//  12 Characters ,1 Uppercase Letter, 1 Number, 1 Special Character
		const regex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/)
		return regex.test(password)
	}

	hideSeekPassword() {
		if (this.visiblity == 'visibility_off') {
			this.visiblity = 'visibility'
			this.passwordType = 'text'
		} else {
			this.visiblity ='visibility_off'
			this.passwordType = 'password'
		}
	}

	borderChange() {

		if ( this.newPassword.length > 3) {
			const isValidNewPassword = this.validatePassword(this.newPassword)
			if (isValidNewPassword === true) {
				this.borderClassNewPassword = 'border border-success'
			}	else {
				this.borderClassNewPassword = 'border border-danger'
			}
		} else {
			this.borderClassNewPassword =''
		}

		if (this.newPasswordConfirm.length > 3) {
			const isValidConfirmPassword = this.validatePassword(this.newPasswordConfirm)
			if (isValidConfirmPassword === true && this.newPassword === this.newPasswordConfirm) {
				this.borderClassConfirmPassword = 'border border-success'
			}	else {
				this.borderClassConfirmPassword = 'border border-danger'
			}
		} else {
			this.borderClassConfirmPassword =''
		}

	}
}

