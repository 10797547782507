import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { RouterLink } from '@angular/router'
import { ActionButtonComponent } from '../../action-button/action-button.component'
import { FindParams, Page } from '../../crud-controller.interface'
import { SchemaObject } from '../../schema.interface'
import { TPipe } from '../../t'
import { ValueComponent } from '../../value/value.component'

@Component({
	selector: 'app-dynamic-table',
	standalone: true,
	imports: [TPipe, FormsModule, ValueComponent, ActionButtonComponent, RouterLink],
	templateUrl: './dynamic-table.component.html',
	styleUrl: './dynamic-table.component.scss'
})
export class DynamicTableComponent<T extends Record<string, any>> implements OnChanges, OnInit {
	@Input() name!: string
	@Input() schema!: SchemaObject<T>
	@Input() data?: Page<T> | undefined
	@Input() params!: FindParams<T>
	@Input() actions: string[] = []
	@Input() link?: string
	@Input() filtered: string[] = []
	@Output() paramsChange = new EventEmitter<void>()
	@Output() action = new EventEmitter<{ action: string, item: T }>()

	private searchTimeout?: ReturnType<typeof setTimeout>
	pages: number[] = []
	columns: string[] = []

	ngOnInit(): void {
		this.columns = this.schema.order!.filter(key =>
			!this.filtered.includes(key)
			&& this.schema.properties![key]!.findable !== false
			&& this.schema.properties![key]!.display !== false
		)
	}

	ngOnChanges(changes: SimpleChanges) {
		if ((changes['data']?.currentValue?.page !== changes['data']?.previousValue?.page) || (changes['data']?.currentValue?.lastPage !== changes['data']?.previousValue?.lastPage)) {
			const page = this.data!.page
			this.pages = []
			const padding = 4

			for (let i = Math.max(1, page - padding + Math.min(0, this.data!.lastPage - page - padding)); this.pages.length < padding * 2 + 1 && i <= this.data!.lastPage; i++) {
				this.pages.push(i)
			}
		}
	}

	computeLink(item: T) {
		return this.link!.replace(':id', item['id'] || item['_id'])
	}

	emitAction(action: string, item: T) {
		this.action.emit({ action, item })
	}

	change() {
		this.paramsChange.emit()
	}

	search() {
		const timeout = setTimeout(() => {
			this.params.page = 1
			this.change()
		}, 300)

		if (this.searchTimeout) {
			clearTimeout(this.searchTimeout)
		}

		this.searchTimeout = timeout
	}

	sort(key: keyof T) {
		if (!this.schema.properties?.[key]?.sortable) {
			return
		}

		this.params.sort = key

		if (this.data!.sort === key) {
			this.params.order = this.data!.order === 'asc' ? 'desc' : 'asc'
		} else {
			this.params.order = 'asc'
		}

		this.change()
	}

	page(page: number) {
		if (page === this.params.page) return
		this.params.page = page
		this.change()
	}
}
