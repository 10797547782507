import { Component, OnInit } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { combineLatestWith } from 'rxjs'
import { Area, ProviderRemuneration } from '../../../api/models'
import { ApiService } from '../../../api/services'
import { ToastService } from '../../components/common/toast/service/toast.service'
import { TPipe, t } from '../../t'

@Component({
	selector: 'app-provider-remuneration-page',
	standalone: true,
	imports: [TPipe, FormsModule, ReactiveFormsModule],
	templateUrl: './provider-remuneration-page.component.html',
	styleUrl: './provider-remuneration-page.component.scss'
})
export class ProviderRemunerationPageComponent implements OnInit {

	id = ''
	areas!: Area[]
	remuneration?: ProviderRemuneration[]
	disabled = false

	constructor(
		private route: ActivatedRoute,
		private api: ApiService,
		private toastService: ToastService
	) { }

	ngOnInit(): void {
		for (const path of this.route.pathFromRoot) {
			if (path.snapshot.params['id']) {
				this.id = path.snapshot.params['id']!
				break
			}
		}

		this.api.provider.readRemuneration({ id: this.id }).pipe(combineLatestWith(this.api.area.find({ body: { limit: 100 } }))).subscribe(([remuneration, areas]) => {
			remuneration = remuneration || []
			this.areas = areas.items

			this.remuneration = []
			for (const area of this.areas) {
				const rem = remuneration.find(r => r.area === area._id)
				if (rem) {
					this.remuneration.push(rem)
				} else {
					this.remuneration.push({ area: area._id, revShare: area.defaultRevShare })
				}
			}
		})
	}

	submit() {
		if (this.disabled) {
			return
		}

		this.disabled = true

		this.api.provider.updateRemuneration({ id: this.id, body: this.remuneration! }).subscribe({
			next: () => {
				this.toastService.success(t('ProviderRenumeration.form.success.update'))
				this.disabled = false
			},
			error: error => {
				this.disabled = false
				throw error
			}
		})
	}

}
