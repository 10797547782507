
import { formatDate } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { isFunction } from 'lodash'
import { SchemaObject } from '../schema.interface'
import { t } from '../t'

@Component({
	selector: 'app-value',
	standalone: true,
	imports: [],
	templateUrl: './value.component.html',
	styleUrl: './value.component.scss'
})
export class ValueComponent implements OnInit {

	@Input() name?: string
	@Input() type?: string
	@Input() schema?: SchemaObject
	@Input() value: any

	display: any

	ngOnInit(): void {
		this.display = this.schema ? this.convert(this.value, this.schema) : this.value
	}

	convert(value: any, schema: SchemaObject) {
		if (schema.type) {
			const displayer = (<any>this)[schema.type]
			if (isFunction(displayer)) {
				return displayer.call(this, value, schema)
			}
		}

		return value
	}

	boolean(value: boolean) {
		return value ? t('yes') : t('no')
	}

	string(value: string, schema: SchemaObject) {
		if (!value) {
			return ''
		}

		if (schema.enum) {
			return t(`${this.type}.enum.${this.name}.${value}`)
		}

		if (schema.format === 'date-time') {
			return formatDate(value, 'fullDate', 'fr', 'GMT+1')
		}

		return value
	}

	array(value: any[], schema: SchemaObject) {
		let result = value

		if (schema.items) {
			result = value.map(item => this.convert(item, schema.items!))
		}

		return result.join(', ')
	}
}
