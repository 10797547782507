import { Component } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { DialogComponent } from '../dialog/dialog.component'
import { MenuComponent } from '../menu/menu.component'

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.scss'],
	standalone: true,
	imports: [MenuComponent, RouterOutlet, DialogComponent]
})
export class MainComponent {

}
