@if (initialized) {
  <main class="main" id="top">
    <div class="container-fluid">
      <div class="row min-vh-100 flex-center g-0">
        <div class="col-lg-8 col-xxl-5 py-3 position-relative">
          <div class="card overflow-hidden z-index-1">
            <div class="card-body p-0">
              <div class="row g-0 h-100">
                <div class="col-md-5 text-center bg-card-gradient">
                  <div class="position-relative p-4 pt-md-5 pb-md-7 light">
                    <div class="bg-holder bg-auth-card-shape" style="background-image: url(../../assets/images/Blue-starry-stars-space_m.webp)"></div>
                    <div class="z-index-1 position-relative">
                      <a class="link-light mb-4 font-sans-serif fs-4 d-inline-block fw-bolder" href="../../../index.html">leads.fr</a>
                      <p class="opacity-75 text-white">Recevez chaque jour des prospects ultra-qualifiés qui ont besoin de vous.</p>
                    </div>
                  </div>
                  <div class="mt-3 mb-4 mt-md-4 mb-md-5 light">
                    <p class="text-white">
                      <a class="text-decoration-underline link-light" href="../../../pages/authentication/card/register.html"> S'inscrire !</a>
                    </p>
                    <p class="mb-0 mt-4 mt-md-5 fs--1 fw-semi-bold text-white opacity-75">
                      Lisez nos
                      <a class="text-decoration-underline text-white" href="#!">Mentions légales</a>
                    </p>
                  </div>
                </div>
                <div class="col-md-7 d-flex flex-center">
                  <div class="p-4 p-md-5 flex-grow-1">
                    <div class="row flex-between-center"></div>
                    <p>
                      <em> {{ message }} </em>
                    </p>
                    <form #loginForm="ngForm">
                      <div class="mb-3">
                        <label class="form-label" for="name">{{ "login.login" | t }}</label>
                        <input class="form-control" id="name" [(ngModel)]="name" name="name" type="email" required />
                      </div>
                      <div class="mb-3">
                        <label class="form-label" for="password">{{ "login.password" | t }}</label>
                        <input class="form-control" id="password" [(ngModel)]="password" name="password" type="password" required />
                      </div>
                      <div class="row flex-between-center">
                        <div class="col-auto"></div>
                        <div class="col-auto">
                          <a class="fs--1" href="/public/forgotpassword">{{ "login.forgot-password" | t }}</a>
                        </div>
                      </div>
                      <div class="mb-3">
                        <button (click)="login()" class="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">{{ "login.submit" | t }}</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
}
