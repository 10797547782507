
import RootResponse from './RootResponse.json'
import LoginDto from './LoginDto.json'
import User from './User.json'
import TokenDto from './TokenDto.json'
import ForgotPasswordDto from './ForgotPasswordDto.json'
import ResetPasswordDto from './ResetPasswordDto.json'
import SearchDto from './SearchDto.json'
import SearchPage from './SearchPage.json'
import Client from './Client.json'
import AreaPropertyEnum from './AreaPropertyEnum.json'
import AreaPropertyValidation from './AreaPropertyValidation.json'
import AreaProperty from './AreaProperty.json'
import Area from './Area.json'
import ProviderRemuneration from './ProviderRemuneration.json'
import Provider from './Provider.json'
import Sitekey from './Sitekey.json'
import Order from './Order.json'
import CreateLeadDto from './CreateLeadDto.json'

export default <Record<string, any>> {
	RootResponse,
	LoginDto,
	User,
	TokenDto,
	ForgotPasswordDto,
	ResetPasswordDto,
	SearchDto,
	SearchPage,
	Client,
	AreaPropertyEnum,
	AreaPropertyValidation,
	AreaProperty,
	Area,
	ProviderRemuneration,
	Provider,
	Sitekey,
	Order,
	CreateLeadDto
}
