@for (option of config.items?.enum; track option) {
  <div class="form-check">
    <input
      [(ngModel)]="checkboxes[option]"
      (ngModelChange)="change()"
      class="form-check-input"
      id="check-{{ option }}-{{ id }}"
      name="check-{{ option }}-{{ id }}"
      type="checkbox"
      value="{{ option }}"
    />
    <label class="form-check-label" for="check-{{ option }}-{{ id }}">{{ config.name + ".enum." + config.key + "." + option | t }}</label>
  </div>
}
