<app-dynamic-table
  #table
  name="{{ entity }}"
  link="{{ link }}"
  [schema]="schema"
  [params]="params"
  [data]="data"
  [actions]="actions"
  [filtered]="filtered"
  (paramsChange)="refresh()"
  (action)="action($event.action, $event.item)"
>
</app-dynamic-table>

<app-modal #modal title="{{ entity + '.form.title.update' | t }}">
  <app-entity-form #form entity="{{ entity }}" [data]="item" [refs]="refs" (save)="closeModal()"></app-entity-form>
</app-modal>
